<template>
  <div class="service-info">
    <el-form
      ref="form"
      :model="formData"
      :rules="formRules"
      label-position="top"
    >
      <el-form-item label="服務人員" prop="serviceUnit">
        <ServicesUnitSelect
          data-cy="unit-select"
          :model.sync="formData.serviceUnit"
          @change="serviceUnitChange(formData.serviceUnit), (formData.totalTime = 0)"
        />
      </el-form-item>
      <el-form-item label="服務項目" prop="service">
        <!-- <UnitServicesSelect
          data-cy="service-select"
          showAll
          :unitId="getData(formData.serviceUnit, 'id', '')"
          :model.sync="formData.service"
          @change="serviceChange"
        /> -->
        <el-select
          v-model="formData.service"
          value-key="id"
          filterable
          clearable
          @change="serviceChange"
          @clear="serviceChange"
        >
          <el-option
            v-for="service in filterServiceOptions"
            :key="service.id"
            :value="service"
            :label="service.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-if="enableSubService" label="子項目" prop="subService">
        <el-select v-model="formData.subService" value-key="id" @change="subServiceChange">
          <el-option
            v-for="service in subServiceList"
            :key="service.id"
            :label="service.name"
            :value="service"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="附加服務">
        <AttachServiceSelect
          data-cy="attach-service-select"
          :model.sync="formData.attachServices"
          :services="formData.service"
          multiple
          @change="attachServiceChange"
        />
      </el-form-item>

      <el-form-item v-if="showResourceService && checkResource" label="服務設備" prop="resourceItemId">
        <template slot="label">
          <div class="inline-flex items-center" style="gap: 8px">
            <p>服務設備</p>
          </div>
        </template>
        <ResourceUnitSelect data-cy="unit-binding-select" :model.sync="formData.resourceItemId" :data="resourceItemList" :showAll="true" />
      </el-form-item>

      <el-form-item label="預估服務時數(分鐘)" prop="totalTime">
        <el-select
          v-model="formData.totalTime"
          data-cy="total-time-select"
          placeholder="請選擇"
          @change="totalTimeChange"
        >
          <el-option
            v-for="(item, index) in genNums"
            :key="index"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-if="showResourceService && checkResource" label="預估設備使用時數(分鐘)" prop="resourceTotalTime">
        <template slot="label">
          <div class="flex items-center" style="gap: 8px">
            <p>預估設備使用時數(分鐘)</p>
            <el-tooltip placement="right">
              <div slot="content">
                使用設備的起始時間與服務項目開<br>
                始時間一致。可調整此欄位的數<br>
                值，提前結束設備使用的時間
              </div>
              <span class="material-icons">help_outline</span>
            </el-tooltip>
          </div>
        </template>
        <div
          v-if="!formData.totalTime"
          class="text-gray-100"
        >
          請先選擇預估服務時數
        </div>
        <el-select
          v-else
          v-model="formData.resourceTotalTime"
          data-cy="total-time-select"
          placeholder="請選擇"
        >
          <el-option
            v-for="(item, index) in getResourceNums"
            :key="index"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="是否為加班時段">
        <el-radio-group
          v-model="formData.isOverTime"
          @change=";(formData.time = ''), (formData.date = '')"
        >
          <el-radio :label="true" data-cy="main-overTime-yes">是</el-radio>
          <el-radio :label="false" data-cy="main-overTime-no">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <hr style="margin-bottom: 16px">
      <el-form-item label="預約日期" prop="date" :rules="dateError()">
        <div
          v-if="!showDateSelect"
          class="text-gray-100"
        >
          請先選擇預服務人員與服務
        </div>
        <el-date-picker
          v-if="showDateSelect"
          ref="picker"
          v-model="formData.date"
          data-cy="date-select"
          :picker-options="pickerOptions"
          type="date"
          placeholder="選擇日期"
          @focus="getDate"
          @change="dateChange"
        />
      </el-form-item>
      <el-form-item ref="periodSelect" show-message label="預約時段" prop="time" :rules="periodError()">
        <div v-if="!showPeriodSelect" class="text-gray-100">請先選擇預約日期</div>
        <el-select
          v-if="showPeriodSelect"
          v-model="formData.time"
          placeholder="請選擇"
          no-data-text="無數據"
        >
          <el-option
            v-for="(item, index) in showAvailableTimes"
            :key="index"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="peopleCount === 1 && config.allowOtherReservation"
        label="是否有當日其他服務"
      >
        <el-radio-group v-model="formData.otherService">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import UnitServicesSelect from '@/components/Select/UnitServicesSelect.vue'
import ServicesUnitSelect from '@/components/Select/ServicesUnitSelect.vue'
import AttachServiceSelect from '@/components/Select/AttachServiceSelect.vue'
import ResourceUnitSelect from '@/components/Select/ResourceUnitSelect.vue'
import { GetAvailableTimes } from '@/api/reservation'
import { FindService, GetService } from '@/api/service'
import { generateTimes, generateNumbers, sortOrder } from '@/utils/helper'
import { mapGetters } from 'vuex'
import { getMonth, thisMonth } from '@/utils/date'
import dayjs from '@/lib/dayjs'
import { noEmptyRules } from '@/validation'
import store from '@/store'
import { get, filter, map, find, isEmpty } from 'lodash'
import { defineComponent, onMounted, ref, reactive, computed } from 'vue'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { usePermissions } from '@/use/permissions'
import { FindServiceUnit } from '@/api/serviceUnit'

export default defineComponent({
  name: 'BlockServiceInfo',
  components: {
    // UnitServicesSelect,
    ServicesUnitSelect,
    AttachServiceSelect,
    ResourceUnitSelect,
  },
  props: {
    peopleCount: {
      type: Number,
      default: 0,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    selectUnit: [Object, String],
    selectDate: [String, Date],
    selectPeriod: String,
  },
  setup (props, { emit }) {
    const { fetchAllOld } = useFetch()
    const { shopId } = useShop()
    const { checkAction } = usePermissions()
    const allServices = ref([])

    const formData = reactive({
      otherService: false,
      service: null,
      subService: null,
      attachServices: null,
      resourceTotalTime: 0,
      serviceUnit: null,
      totalTime: 0,
      isOverTime: false,
      resourceItemId: null,
      date: '',
      time: '',
    })

    const shopTimeUnit = computed(() => get(store.getters, 'shopTimeUnit'))

    const checkResource = computed(() => {
      return checkAction('admin.resourceItem.page')
    })

    const resourceItemList = computed(() => {
      const subService = get(formData.service, 'AppointmentSubServices')
      const result = filter(get(formData.service, 'ResourceItems'), (i) => i.enabled && !i.isRemove)
      if (subService && formData.subService) {
        if (get(subService, 'allResourceItem')) return result
        const subResourceItems = get(formData.subService, 'ResourceItems')
        if (isEmpty(subResourceItems)) return result
        else return filter(result, i => find(subResourceItems, { id: i.id }) && i.enabled && !i.isRemove)
      }
      return result
    })

    const showResourceService = computed(() => {
      if (get(formData.service, 'enableAppointmentUnit') && get(formData.service, 'enableResourceItem')) return true
      return false
      // return includes(['resourceService', 'humanAndResourceService'], formData.type)
    })

    // const getNumsByValue = (value) => {
    //   return generateNumbers(0, value + shopTimeUnit.value, shopTimeUnit.value)
    // }
    const genNums = ref([])
    const getResourceNums = ref([])

    // const getAllService = async () => {
    //   await fetchAllOld(GetService, { shopId: shopId.value }, (res) => {
    //     allServices.value = filter(res, (service) => service.enableAppointmentUnit)
    //     if (checkResource.value) {
    //       allServices.value = filter(res, (service) => !service.enableAppointmentUnit && service.enableResourceItem)
    //     } else {
    //       allServices.value = filter(res, (service) => service.enableAppointmentUnit && !service.enableResourceItem)
    //     }
    //   })
    // }

    onMounted(async () => {
      // getAllService()
    })

    return {
      allServices,
      resourceItemList,
      formData,
      showResourceService,
      checkResource,
      shopTimeUnit,
      // getNumsByValue,
      getResourceNums,
      shopId,
      genNums,
    }
  },
  data () {
    return {
      haveError: false,
      availableTimes: [],
      availableDate: [],
      checking: false,

      formRules: {
        service: [noEmptyRules()],
        subService: [noEmptyRules()],
        serviceUnit: [noEmptyRules()],
        date: [noEmptyRules()],
        time: [noEmptyRules()],
        resourceItemId: [noEmptyRules()],
      },

      pickerOptions: {
        disabledDate: (date) => {
          if (this.formData.isOverTime) return false
          const d = dayjs(date).format('YYYY/MM/DD')
          return !this.availableDate.find((item) => item === d)
        },
      },
      serviceOptions: [],
    }
  },

  computed: {
    totalTime () {
      const form = this.formData
      if (!form.service) return 0
      let base = 0

      if (!form.subService) base += form.service.bookingTime
      if (form.subService) base += form.subService.bookingTime

      let attach = 0
      if (form.attachServices) {
        form.attachServices.forEach((item) => {
          attach += item.bookingTime
        })
      }

      return base + attach
    },
    showDateSelect () {
      return this.formData.serviceUnit && this.formData.service
    },
    showPeriodSelect () {
      return this.formData.serviceUnit && this.formData.service && this.formData.date
    },
    showAvailableTimes () {
      if (this.formData.isOverTime) return this.genTimes()
      return map(this.availableTimes, t => dayjs(t).format('HH:mm'))
    },
    subServiceList () {
      if (!this.formData.service) return []
      if (this.showResourceService && this.useServiceResource) return get(this.formData, 'service.AppointmentSubServices')
      return filter(get(this.formData, 'service.AppointmentSubServices'), (s) => find(s.AppointmentUnits, { id: get(this.formData, 'serviceUnit.id') }) || isEmpty(s.AppointmentUnits))
      // return this.formData.service.AppointmentSubServices.filter(s => find(s.AppointmentUnits, { id: this.formData.serviceUnit.id }))
    },
    enableSubService () {
      return get(this.formData.service, 'enableSubService')
    },
    filterServiceOptions () {
      let serviceOptionList = []
      if (this.checkResource) {
        if (this.formData.type === 'resourceService') {
          serviceOptionList = filter(this.serviceOptions, (i) => !i.enableAppointmentUnit && i.enableResourceItem)
        } else if (this.formData.type === 'humanService') {
          serviceOptionList = filter(this.serviceOptions, (i) => i.enableAppointmentUnit && !i.enableResourceItem)
        } else if (this.formData.type === 'humanAndResourceService') {
          serviceOptionList = filter(this.serviceOptions, (i) => i.enableAppointmentUnit && i.enableResourceItem)
        } else {
          serviceOptionList = this.serviceOptions
        }
      } else {
        serviceOptionList = filter(this.serviceOptions, (i) => i.enableAppointmentUnit && !i.enableResourceItem)
      }
      return serviceOptionList
    },
  },
  watch: {
    formData: {
      handler: function (data) {
        return this.$emit('update', data)
      },
      deep: true,
    },
    async selectPeriod () {
      const date = dayjs(this.formData.date).format('YYYY/MM/DD')
      const dateTime = dayjs(`${date} ${this.selectPeriod}`)
      // const addOffsetTime = dateTime.add(this.config.timeUnitOffset, 'm')
      // this.formData.time = addOffsetTime.format('HH:mm')
      this.formData.time = dateTime.format('HH:mm')
    },
    async selectDate () {
      this.refreshAvailableTimes()
      // setTimeout(async () => {
      //   await this.getDate(this.selectDate)
      //   this.dateChange(this.selectDate)
      //   this.formData.date = this.selectDate
      // }, 300)
    },
    showPeriodSelect (show) {
      if (show) {
        if (this.selectPeriod && this.selectDate) {
          const date = dayjs(this.formData.date).format('YYYY/MM/DD')
          const dateTime = dayjs(`${date} ${this.selectPeriod}`)
          // const addOffsetTime = dateTime.add(this.config.timeUnitOffset, 'm')
          setTimeout(() => {
            this.formData.time = dateTime.format('HH:mm')
            // this.formData.time = addOffsetTime.format('HH:mm')
          }, 300)
        }
      } else this.formData.time = ''
    },
    showDateSelect (show) {
      if (show) {
        this.refreshAvailableTimes()
        // this.formData.date = ''
        // setTimeout(async () => {
        //   await this.getDate(this.selectDate)
        //   this.dateChange(this.selectDate)
        //   this.formData.date = this.selectDate
        // }, 300)
      } else this.formData.date = ''
    },
    async 'formData.totalTime' () {
      if (this.showDateSelect) {
        this.formData.date = ''
        this.refreshAvailableTimes()
        // setTimeout(async () => {
        //   await this.getDate(this.selectDate)
        //   this.dateChange(this.selectDate)
        //   this.formData.date = this.selectDate
        // }, 300)
      }
      if (this.showPeriodSelect) {
        if (this.selectPeriod && this.selectDate) {
          this.formData.time = ''
          const date = dayjs(this.formData.date).format('YYYY/MM/DD')
          const dateTime = dayjs(`${date} ${this.selectPeriod}`)
          // const addOffsetTime = dateTime.add(this.config.timeUnitOffset, 'm')
          setTimeout(() => {
            this.formData.time = dateTime.format('HH:mm')
            // this.formData.time = addOffsetTime.format('HH:mm')
          }, 300)
        }
      }
    },
  },
  async mounted () {
    this.$nextTick(() => {
      this.$emit('ref', this.$refs.form)
    })
    if (this.selectUnit) {
      this.formData.serviceUnit = this.selectUnit
      this.findServiceUnit(this.selectUnit.id)
    }
    await this.getDate(this.selectDate)
    this.dateChange(this.selectDate)
  },
  methods: {
    refreshAvailableTimes () {
      setTimeout(async () => {
        if (this.checking) return
        this.checking = true
        await this.getDate(this.selectDate)
        this.dateChange(this.selectDate)
        this.formData.date = this.selectDate
        this.checking = false
      }, 300)
    },
    dateError () {
      const rule = (rule, value, callback) => {
        if (this.formData.isOverTime) {
          this.haveError = false
          callback()
        }
        const exist = find(this.availableDate, i => {
          return dayjs(i).format('YYYY/MM/DD') === dayjs(this.formData.date).format('YYYY/MM/DD')
        })
        if (!exist && this.showDateSelect) {
          this.haveError = true
          callback(new Error('此日無法使用！'))
        } else {
          this.haveError = false
          callback()
        }
      }
      return [noEmptyRules(), { validator: rule, trigger: 'change' }]
    },
    periodError () {
      const rule = (rule, value, callback) => {
        if (this.formData.isOverTime) {
          this.haveError = false
          callback()
        }
        const exist = find(this.showAvailableTimes, i => i === this.formData.time)
        if (!exist && this.showPeriodSelect) {
          this.haveError = true
          callback(new Error('此時段無法使用！'))
        } else {
          this.haveError = false
          callback()
        }
      }
      return [noEmptyRules(), { validator: rule, trigger: 'change' }]
    },
    getData (obj, path, defaultValue) {
      return get(obj, path, defaultValue)
    },
    async getAvailableTimes ({ start, end }) {
      if (!get(this.formData, 'service.id')) return
      const [res] = await GetAvailableTimes({
        shopId: this.shopId,
        rangeStart: start.toDate(),
        rangeEnd: end.toDate(),
        resourceItemIds: this.formData.resourceItemId ? [get(this.formData.resourceItemId, 'id')] : null,
        appointmentUnitIds: [this.formData.serviceUnit.id],
        appointmentServiceId: get(this.formData, 'service.id') || undefined,
        appointmentSubServiceId: get(this.formData, 'subService.id'),
        appointmentServiceAttachIds: map(
          get(this.formData, 'attachServices', []),
          'id',
        ),
        peopleCount: this.peopleCount,
        setTotalBookingTime: this.formData.totalTime,
        setResourceItemTotalBookingTime: this.formData.resourceTotalTime,
      })

      const list = []
      if (res.times) {
        res.times.forEach((item) => {
          list.push(dayjs(item).format('YYYY/MM/DD'))
        })
        this.availableDate = Array.from(new Set(list))
        this.availableTimes = filter(res.times, (item) => {
          return dayjs(item).isSame(this.formData.date, 'date')
        })
      }
    },

    genTimes () {
      const offset = this.config.timeUnitOffset
      const timeUnit = this.config.timeUnit
      return generateTimes(timeUnit, offset)
    },

    async getDate (selectDate) {
      this.$nextTick(async () => {
        let dateStart
        let dateEnd
        if (selectDate) {
          const year = dayjs(selectDate).year()
          const month = dayjs(selectDate).month()
          const { start, end } = getMonth(year, month)
          dateStart = start
          dateEnd = end
        }
        if (get(this.$refs, 'picker.picker')) {
          const year = this.$refs.picker.picker.year
          const month = this.$refs.picker.picker.month
          const { start, end } = getMonth(year, month + 1)
          dateStart = start
          dateEnd = end
        } else {
          const { start, end } = thisMonth()
          dateStart = start
          dateEnd = end
        }

        // await this.getAvailableDate(dateStart, dateEnd)
        await this.getAvailableTimes({ start: dayjs(dateStart), end: dayjs(dateEnd) })
        // if (this.pickerAddEvent) return

        const pre = document.querySelector(
          '.el-picker-panel__icon-btn.el-date-picker__prev-btn.el-icon-arrow-left',
        )
        const next = document.querySelector(
          '.el-picker-panel__icon-btn.el-date-picker__next-btn.el-icon-arrow-right',
        )
        const preYear = document.querySelector(
          '.el-picker-panel__icon-btn.el-date-picker__prev-btn.el-icon-d-arrow-left',
        )
        const nextYear = document.querySelector(
          '.el-picker-panel__icon-btn.el-date-picker__next-btn.el-icon-d-arrow-right',
        )

        const task = async () => {
          this.availableDate = []

          const year = this.$refs.picker.picker.year
          const month = this.$refs.picker.picker.month
          const { start, end } = getMonth(year, month + 1)

          // await this.getAvailableDate(start, end)
          await this.getAvailableTimes({ start: dayjs(start), end: dayjs(end) })
        }

        if (pre) {
          pre.removeEventListener('click', task)
          pre.addEventListener('click', task)
        }
        if (next) {
          next.removeEventListener('click', task)
          next.addEventListener('click', task)
        }
        if (preYear) {
          preYear.removeEventListener('click', task)
          preYear.addEventListener('click', task)
        }
        if (nextYear) {
          nextYear.removeEventListener('click', task)
          nextYear.addEventListener('click', task)
        }
        // this.pickerAddEvent = true
      })
    },

    dateChange (date) {
      const start = dayjs(date).startOf('d')
      const end = dayjs(date).add(1, 'd').startOf('d')
      // this.formData.time = ''
      this.getAvailableTimes({
        start: dayjs(start),
        end: dayjs(end),
      })
    },
    updateTimeOptions () {
      this.genNums = generateNumbers(0, 600, this.shopTimeUnit)
      this.genNums.push(this.totalTime)
      this.genNums = [...new Set(this.genNums.sort((a, b) => a - b))]
      this.getResourceNums = generateNumbers(0, this.formData.totalTime + this.shopTimeUnit, this.shopTimeUnit)
      this.getResourceNums.push(this.totalTime)
      this.getResourceNums = [...new Set(filter(this.getResourceNums.sort((a, b) => a - b), (item) => item <= this.formData.totalTime))]
    },
    totalTimeChange () {
      if (this.formData.isOverTime) return
      this.formData.date = this.selectDate
      this.formData.resourceTotalTime = this.formData.totalTime
      this.updateTimeOptions()
      // this.formData.date = ''
    },

    serviceUnitChange (serviceUnit) {
      if (serviceUnit) this.$emit('unitChange')
      this.formData.service = ''
      this.formData.attachServices = []
      this.findServiceUnit(serviceUnit.id)
      this.updateTimeOptions()
      // this.formData.date = this.selectDate
      // this.formData.date = ''
      // this.formData.time = ''
    },
    async findServiceUnit (unitId) {
      if (unitId === '' || unitId === undefined) return
      try {
        const res = await FindServiceUnit({
          shopId: this.shopId,
          id: unitId,
        })

        this.serviceOptions = sortOrder(res.AppointmentServices)
      } catch (error) {
        console.log(error)
      }
    },
    subServiceChange (service) {
      this.formData.subService = service
      this.formData.totalTime = this.totalTime
      this.formData.resourceTotalTime = this.totalTime
      this.formData.resourceItemId = null
      this.updateTimeOptions()
    },

    async serviceChange () {
      await this.findService()
      this.formData.attachServices = []
      this.formData.subService = null
      this.formData.totalTime = this.totalTime
      this.formData.resourceTotalTime = this.totalTime
      this.updateTimeOptions()
      // this.formData.date = this.selectDate
      // this.formData.date = ''
      // this.formData.time = ''
    },

    attachServiceChange () {
      // this.formData.date = ''
      // this.formData.date = this.selectDate
      // this.formData.time = ''
      this.formData.resourceTotalTime = this.totalTime
      this.formData.totalTime = this.totalTime
      this.updateTimeOptions()
    },

    async findService () {
      const [res, err] = await FindService({
        shopId: this.shopId,
        id: this.formData.service.id,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.formData.service = res
    },
  },
})
</script>

<style scoped lang="postcss"></style>

<template>
  <div class="daily-view">
    <PageTitleExtra
      title="當日預約行事曆"
      btn="新增預約"
      btn2="關閉時段"
      btn3="顯示設定"
      btnFeature="admin.appointmentOrder.create"
      :hideBtn="dialog.create"
      @btnClick="onCreateReservation"
      @btn2Click="onTogglePeriod"
      @btn3Click="dialog.settings = true"
    />
    <!-- <TimeRangeSetting @change="onTimeRangeChange" /> -->
    <DisplaySettingsDialog
      v-if="dialog.settings"
      @close="dialog.settings = false"
      @confirm="onTimeRangeChange"
    />

    <div v-if="!dialog.create" class="flex justify-between" style="margin-bottom: 12px">
      <FiltersContainer>
        <el-date-picker
          v-model="search.date"
          style="width: 340px !important"
          type="date"
          placeholder="選擇日期"
        />
        <el-select
          v-model="search.unit"
          style="width: 340px !important"
          filterable
          clearable
          placeholder="搜尋服務人員"
          value-key="id"
          @change="searchUnitChange"
        >
          <el-option
            v-for="unit in unitList"
            :key="unit.id"
            :label="unit.name"
            :value="unit"
          />
        </el-select>
      </FiltersContainer>

      <Pagination
        style="padding: 0"
        :curPage="page + 1"
        :pageLimit="perPage"
        :total="unitList.length"
        @pageChange="changePage"
      />
    </div>

    <div class="flex w-full" style="gap: 20px">
      <vue-cal
        v-if="showCal"
        v-loading="loading"
        :time-from="startPeriodTime || 0"
        :time-to="lastPeriodTime || 1440"
        :timeCellHeight="180"
        style="width: 100%"
        :style="{
          height: dialog.create ? '700px' : '100%'
        }"
        active-view="day"
        hide-view-selector
        :selected-date="search.date"
        :events="composEvents"
        :disable-views="['years', 'year', 'month', 'week']"
        :split-days="chunkUnitColumn[page]"
        :sticky-split-labels="true"
        :on-event-click="onEventClick"
        :editable-events="false"
        :time-step="timeUnit"
        @view-change="dateChange"
      >
        <template #title="{ title, view }">
          <div class="relative">
            <p>{{ formateTitle(title, view) }}</p>
            <span
              class="material-icons absolute cursor-pointer"
              style="top: 5px; right: -280px"
              @click="search.date = new Date()"
            >
              event_outlined
            </span>
          </div>
        </template>

        <template #time-cell="{ hours, minutes }">
          <div :class="{ 'vuecal__time-cell-line': true, hours: !minutes }">
            <strong v-if="!minutes">{{ hours }}:{{ plusZero(minutes) }}</strong>
            <span v-else class="text-sub">{{ hours }}:{{ plusZero(minutes) }}</span>
          </div>
        </template>

        <template #split-label="{ split }">
          <div class="flex flex-col text-center truncate">
            <p class="truncate" :style="`color: ${split.color}`">{{ split.label }}</p>
            <p>({{ split.reservationCount }})</p>
          </div>
        </template>

        <template #event="{ event }">
          <div class="vuecal__event-time hidden" />
          <div v-if="event.data" style="border-radius: 2px; padding: 4px">
            <div class="flex justify-between items-center">
              <p>
                {{ formatDate(event.start, 'HH:mm') }} - {{ formatDate(event.end, 'HH:mm') }}
              </p>
              <!-- <Tag  class="flex-wrap" type="info">不指定</Tag> -->
              <div v-if="event.data.fromNotSpecify" class="tag flex">
                <span>不指定</span>
              </div>
            </div>
            <p>{{ event.data.newUserName || '未填寫' }}</p>
            <p>{{ event.data.numberCode === 1 ? formatDate(get(event.data, 'Member.UserInfo.birthday'), 'YYYY/MM/DD') : '' }}</p>
            <p>{{ event.data.userPhone || '未填寫' }}</p>
            <p>{{ get(event.reservation, 'AppointmentService.name') }}</p>
            <p v-for="service in showAttachServices(event.reservation)" :key="service">{{ service }}</p>
          </div>
        </template>
      </vue-cal>
      <div v-if="dialog.create" class="overflow-y-auto create-reservation-wrapper">
        <CreateReservationBlock
          :selectUnit="select.unit"
          :selectDate="select.start"
          :selectPeriod="select.period"
          @close="handleCloseCreateReservation"
          @unitChange="createUnitChange"
          @created="dateChange({startDate: curStart})"
        />
      </div>
    </div>

    <!-- 預約資訊側邊彈窗 -->
    <ReservationOrderDetail
      v-if="dialog.info"
      :orderId="select.event.id"
      :selecReservation="select.event"
      @close="dialog.info=false"
      @cancel="onCancel"
      @refresh="dateChange({startDate: curStart})"
    />

    <!-- 關閉時段 Dialog -->
    <TogglePeriodDialog
      v-if="dialog.togglePeriod"
      title="編輯關閉時段"
      width="440px"
      :selectUnit="select.unit"
      :selectDate="select.start"
      @close="dialog.togglePeriod = false"
      @updated="dateChange({startDate: curStart})"
    />

    <DeleteDialog
      v-if="dialog.delete"
      title="警示"
      :content="deleteContent"
      width="40%"
      btnString="取消預約"
      cancelBtnString="返回"
      @close="dialog.delete = false"
      @delete="cancelReservation"
    />

    <WarningDialog
      v-if="dialog.noShift"
      content="此時段未排班，無法新增預約，請至排班行事曆進行排班"
      btnString="前往"
      @close="dialog.noShift = false"
      @confirm="goToUnitSchedule"
    />
  </div>
</template>

<script>
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import { defineComponent } from 'vue'
import PageTitleExtra from '@/components/Title/PageTitleExtra.vue'
import DisplaySettingsDialog from './components/DisplaySettingsDialog.vue'

import CreateReservationBlock from '@/components/Reservation/CreateReservationBlock/CreateReservationBlock.vue'
import ReservationOrderDetail from '@/components/Reservation/ReservationOrderDetail/ReservationOrderDetail.vue'
import TogglePeriodDialog from '@/components/Dialog/TogglePeriodDialog.vue'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'

import { mapGetters } from 'vuex'
import { GetServiceUnit } from '@/api/serviceUnit'
import { FindReservationParameter, GetReservation, GetReservationCount, UpdateReservationOrderStatus } from '@/api/reservation'
import { GetPeriods } from '@/api/togglePeriod'

import { checkFeature } from '@/store/modules/permission'
import { formatDate, weekDayTranslate } from '@/utils/date'
import { generateTimes, getAllDataFromApi, plusZero, timeToNumber } from '@/utils/helper'
import dayjs from '@/lib/dayjs'
import { get, map, find, flattenDeep, chunk, uniq } from 'lodash'
import debounce from 'lodash.debounce'
import ls from 'local-storage'

export default defineComponent({
  name: 'DailyView',
  components: {
    VueCal,
    CreateReservationBlock,
    ReservationOrderDetail,
    TogglePeriodDialog,
    WarningDialog,
    PageTitleExtra,
    DisplaySettingsDialog,
    // TimeRangeSetting,
    // DailyViewOptions,
    // CollapseContainer,
  },
  data: () => ({
    hideNoEvent: true,
    hide: false,
    loading: false,
    deleteContent: '',
    unitList: [],
    page: 0,
    perPage: 5,
    curStart: null,
    timeUnit: 60,
    timeOffset: 0,
    showCal: true,
    startPeriodTime: 0,
    lastPeriodTime: 1440,
    dailyViewSettings: {
      start: 0,
      end: 1440,
    },
    search: {
      unit: null,
      date: new Date(),
    },
    select: {
      start: null,
      unit: null,
      event: null,
      period: null,
    },
    dialog: {
      create: false,
      info: false,
      togglePeriod: false,
      noShift: false,
      delete: false,
      settings: false,
    },
  }),
  computed: {
    ...mapGetters(['shopTimeUnit', 'shop', 'userFeatures']),
    unitColumn () {
      const unitColumn = map(this.unitList, (unit) => ({
        id: unit.id,
        label: unit.name,
        reservationCount: unit.reservationCount,
      }))

      return unitColumn
    },
    chunkUnitColumn () {
      const unitChunk = chunk(this.unitColumn, this.perPage)

      if (this.search.unit) {
        unitChunk[this.page] = unitChunk[this.page].filter(i => i.id.includes(this.search.unit.id))
      }
      return unitChunk
    },
    composEvents () {
      return flattenDeep(map(this.unitList, 'events').filter(i => Boolean(i)))
    },
    FirstAvailableTime () {
      const unitIds = map(this.chunkUnitColumn[this.page], 'id')
      const events = []
      unitIds.forEach(id => {
        const unit = find(this.unitList, { id })
        if (unit.times) {
          const times = unit.times.filter(i => dayjs(this.curStart).isSame(dayjs(i.start), 'd') && i.status === 'available')
          events.push(...map(times, 'start'))
        }
      })
      return uniq(events).sort((a, b) => {
        if (dayjs(a).isAfter(dayjs(b))) return 1
        if (dayjs(b).isAfter(dayjs(a))) return -1
      })[0] || 0
    },
    FirstEventTime () {
      const unitIds = map(this.chunkUnitColumn[this.page], 'id')
      const events = []
      unitIds.forEach(id => {
        const unit = find(this.unitList, { id })
        if (unit.events) {
          const times = unit.events.filter(i => !i.noShift)
          events.push(...map(times, 'start'))
        }
      })
      return events.sort((a, b) => {
        if (dayjs(a).isAfter(dayjs(b))) return 1
        if (dayjs(b).isAfter(dayjs(a))) return -1
      })[0] || 0
    },
    // startPeriodTime () {
    //   // let time
    //   // // if (this.FirstEventTime) time = dayjs(this.FirstEventTime)
    //   // // else if (this.FirstAvailableTime) time = dayjs(this.FirstAvailableTime)
    //   // if (this.FirstAvailableTime === 0 && this.FirstEventTime === 0) return 0
    //   // else if (!this.FirstEventTime && this.FirstAvailableTime) time = dayjs(this.FirstAvailableTime)
    //   // else if (this.FirstEventTime && !this.FirstAvailableTime) time = dayjs(this.FirstEventTime)
    //   // else if (dayjs(this.FirstEventTime).isBefore(dayjs(this.FirstAvailableTime))) time = dayjs(this.FirstEventTime)
    //   // else if (dayjs(this.FirstAvailableTime).isBefore(dayjs(this.FirstEventTime))) time = dayjs(this.FirstAvailableTime)
    //   // else if (dayjs(this.FirstAvailableTime).isSame(dayjs(this.FirstEventTime))) time = dayjs(this.FirstAvailableTime)
    //   // if (!time) return 0
    //   // return timeToNumber(time.format('HH:mm'))
    //   const dailyViewSettings = ls.get('dailyViewSettings')
    //   return timeToNumber(dailyViewSettings.start)
    // },

    LastAvailableTime () {
      const unitIds = map(this.chunkUnitColumn[this.page], 'id')
      const events = []
      unitIds.forEach(id => {
        const unit = find(this.unitList, { id })
        if (unit.times) {
          const times = unit.times.filter(i => dayjs(this.curStart).isSame(dayjs(i.start), 'd') && i.status === 'available')
          events.push(...map(times, 'start'))
        }
      })
      return uniq(events).sort((a, b) => {
        if (dayjs(a).isAfter(dayjs(b))) return -1
        if (dayjs(b).isAfter(dayjs(a))) return 1
      })[0] || 0
    },
    LastEventTime () {
      const unitIds = map(this.chunkUnitColumn[this.page], 'id')
      const events = []
      unitIds.forEach(id => {
        const unit = find(this.unitList, { id })
        if (unit.events) {
          const times = unit.events.filter(i => !i.disabled)
          events.push(...map(times, 'start'))
        }
      })
      return events.sort((a, b) => {
        if (dayjs(a).isAfter(dayjs(b))) return -1
        if (dayjs(b).isAfter(dayjs(a))) return 1
      })[0] || 0
    },
    // lastPeriodTime () {
    //   // let time
    //   // if (!this.LastEventTime && this.LastAvailableTime) time = dayjs(this.LastAvailableTime)
    //   // else if (this.LastEventTime && !this.LastAvailableTime) time = dayjs(this.LastEventTime)
    //   // else if (dayjs(this.LastEventTime).isAfter(dayjs(this.LastAvailableTime))) time = dayjs(this.LastEventTime)
    //   // else if (dayjs(this.LastAvailableTime).isAfter(dayjs(this.LastEventTime))) time = dayjs(this.LastAvailableTime)
    //   // if (!time) return 0
    //   // return timeToNumber(time.format('HH:mm'))
    //   const dailyViewSettings = ls.get('dailyViewSettings')
    //   return timeToNumber(dailyViewSettings.end)
    // },

    periods () {
      return generateTimes(this.timeUnit, this.timeOffset)
    },
  },
  watch: {
    unitColumn () {
      setTimeout(() => {
        this.generateCellBlock()
      }, 300)
    },
    page () {
      setTimeout(() => {
        this.generateCellBlock()
      }, 300)
    },
    startPeriodTime () {
      setTimeout(() => {
        this.generateCellBlock()
      }, 300)
    },
    lastPeriodTime () {
      setTimeout(() => {
        this.generateCellBlock()
      }, 300)
    },
  },
  async mounted () {
    this.$store.commit('app/SET_SIDEBAR', false)
    this.getDailyViewSettings()
    await this.getReservationConfig()
    await this.getServiceUnit()
    this.dateChange({
      startDate: new Date(),
    })
    // await this.getReservation(new Date())
    this.generateCellBlock()
  },
  methods: {
    plusZero,
    get,
    formatDate,
    getDailyViewSettings () {
      let settings = ls.get('dailyViewSettings')
      if (!settings) {
        ls.set('dailyViewSettings', {
          start: '00:00',
          end: '24:00',
        })
        settings = ls.get('dailyViewSettings')
      }
      this.lastPeriodTime = timeToNumber(settings.end)
      this.startPeriodTime = timeToNumber(settings.start)
    },
    onTimeRangeChange () {
      this.showCal = false
      this.getDailyViewSettings()
      this.dateChange({ startDate: this.curStart })
      this.generateCellBlock()
      this.showCal = true
      this.dialog.settings = false
      this.$message.success('更新成功')
    },
    handleCloseCreateReservation () {
      this.dialog.create = false
      this.search.unit = null
      setTimeout(() => {
        this.generateCellBlock()
      }, 300)
    },
    searchUnitChange (e) {
      const unitColumn = map(this.unitList, 'id')
      const chunkColumn = chunk(unitColumn, this.perPage)

      for (const page in chunkColumn) {
        if (chunkColumn[page].includes(e.id)) {
          this.page = Number(page)
          return
        }
      }
    },
    async getReservationConfig () {
      try {
        const res = await FindReservationParameter({ shopId: this.shop })
        this.timeUnit = res.timeUnit || 60
        this.timeOffset = res.timeUnitOffset || 0
      } catch (e) {
        // this.$message.error(e)
        console.log(`getReservationConfig error: ${e}`)
      }
    },
    onTogglePeriod () {
      this.select.unit = null
      this.select.start = null
      this.dialog.togglePeriod = true
    },
    onCreateReservation () {
      const target = this.chunkUnitColumn[this.page][0]
      const unit = find(this.unitList, { id: target.id })
      this.select.unit = unit
      this.search.unit = unit
      this.select.start = new Date(this.curStart)
      this.dialog.create = true
      setTimeout(() => {
        this.generateCellBlock()
      }, 500)
    },
    createUnitChange (unit) {
      this.search.unit = unit
      this.searchUnitChange(unit)
    },
    changePage (page) {
      this.page = page - 1
      this.search.unit = null
    },
    goToUnitSchedule () {
      this.$router.push({
        name: 'ShiftsCalendar',
        query: {
          unit: this.select.unit,
          date: formatDate(this.select.start),
          from: 'DailyView',
        },
      })
    },
    formateTitle (title, view) {
      const date = formatDate(view.startDate, 'YYYY/MM/DD')
      const day = weekDayTranslate(dayjs(view.startDate).day())

      return `${date} ${day}`
    },
    onEventClick (event, e) {
      this.select.unit = event.split
      this.select.start = event.start
      if (event.noShift) {
        this.dialog.noShift = true
        return
      }
      if (event.disabled) {
        this.dialog.togglePeriod = true
        return
      }
      this.select.event = event.reservation
      this.dialog.info = true
    },

    generateCellBlock () {
      const cols = document.querySelectorAll('[data-split]')
      cols.forEach(el => {
        const unitId = el.getAttribute('data-split')
        const unit = find(this.unitList, { id: unitId })
        const target = document.querySelector(`[data-split="${unit.id}"]`)
        const oldCellBlocks = Array.from(target.children).filter(i => Array.from(i.classList).includes('cell-block'))
        const noEventBlock = Array.from(target.children).filter(i => Array.from(i.classList).includes('vuecal__no-event'))
        if (noEventBlock.length) target.removeChild(noEventBlock[0])
        oldCellBlocks.forEach(i => target.removeChild(i))
        this.periods.forEach(period => {
          if (timeToNumber(period) >= this.startPeriodTime && timeToNumber(period) < this.lastPeriodTime) {
            const block = document.createElement('div')
            block.style.height = '180px'
            block.classList.add('cell-block')
            block.addEventListener('click', () => {
              if (!checkFeature(this.userFeatures, 'admin.appointmentOrder.create')) return
              this.dialog.create = true
              this.search.unit = unit
              this.select.unit = unit
              this.select.start = this.curStart
              this.select.period = period
              setTimeout(() => {
                this.generateCellBlock()
              }, 500)
            })
            target.appendChild(block)
          }
        })
      })
    },

    async getServiceUnit () {
      try {
        this.unitList = await GetServiceUnit({
          shopId: this.shop,
          // start: this.pageStartIndex,
          // limit: this.tableOptions.pageLimit,
        })
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },
    async getUnitDayPeriods (unit, date) {
      const res = await GetPeriods({
        shopId: this.shop,
        appointmentUnitId: unit.id,
        start: dayjs(date).startOf('date').toDate(),
        end: dayjs(date).endOf('date').toDate(),
      })
      const targetUnit = find(this.unitList, { id: unit.id })
      if (targetUnit) this.$set(targetUnit, 'times', res.times)
      // 禁用全天
      if (!res.times.length) {
        this.$set(targetUnit, 'events', [
          {
            start: dayjs(date).startOf('date').toDate(),
            end: dayjs(date).endOf('date').toDate(),
            allDay: true,
            class: 'no-shift',
            split: targetUnit.id,
            disabled: true,
            noShift: true,
            background: true,
          },
        ])
      }
      if (res.times.length) {
        this.createPeriodEvents(targetUnit, res.times)
      }
    },

    async createPeriodEvents (unit, times) {
      if (!times.length) return
      const startPeriod = this.periodToDate(this.periods[0])
      const firstPeriod = times[0].start
      const endPeriod = this.periodToDate(this.periods[this.periods.length - 1]) // TOFIX bug here
      const lastPeriod = times[times.length - 1].start

      // 開頭時段
      if (!this.isSamePeriod(startPeriod, firstPeriod)) {
        unit.events.push({
          start: startPeriod,
          end: new Date(firstPeriod),
          class: 'no-shift',
          split: unit.id,
          disabled: true,
          noShift: true,
          background: true,
        })
      }

      // 中間時段
      // let tempStart = null
      // for (const p of times) {
      //   const cur = dayjs(p.start)
      //   if (p.status === 'available') {
      //     if (!tempStart) continue
      //     unit.events.push({
      //       start: tempStart.toDate(),
      //       end: cur.toDate(),
      //       class: 'disabled',
      //       background: true,
      //       split: unit.id,
      //       disabled: true,
      //     })
      //     tempStart = null
      //   }
      //   if (p.status === 'ban') {
      //     if (!tempStart) tempStart = cur
      //   }
      // }
      let cur = dayjs(times[0].start)
      let start
      // let end
      let last
      while (!cur.isAfter(dayjs(times[times.length - 1].start).add(this.timeUnit, 'm'))) {
        const exist = find(times, t => dayjs(t.start).isSame(cur))
        if (exist) {
          if (exist.status === 'available') {
            if (last === 'noShift') {
              if (start) {
                unit.events.push({
                  start: start.toDate(),
                  end: cur.toDate(),
                  class: 'no-shift',
                  background: true,
                  split: unit.id,
                  noShift: true,
                })
                start = null
              }
            }
            if (last === 'available') {
              start = null
              // end = null
            }
            if (last === 'ban') {
              if (start) {
                unit.events.push({
                  start: start.toDate(),
                  end: cur.toDate(),
                  class: 'disabled',
                  background: true,
                  split: unit.id,
                  disabled: true,
                })
                start = null
              }
            }
            last = 'available'
          }
          if (exist.status === 'ban') {
            if (last === 'noShift') {
              if (start) {
                unit.events.push({
                  start: start.toDate(),
                  end: cur.toDate(),
                  class: 'no-shift',
                  background: true,
                  split: unit.id,
                  noShift: true,
                })
                start = null
              }
            }
            if (!start) start = dayjs(exist.start)
            last = 'ban'
          }
          if (exist.status === 'booking') {
            if (!start) start = dayjs(exist.start)
            if (last === 'noShift') {
              if (start) {
                unit.events.push({
                  start: start.toDate(),
                  end: cur.toDate(),
                  class: 'no-shift',
                  background: true,
                  split: unit.id,
                  noShift: true,
                })
              }
            }
            if (last === 'ban') {
              if (start) {
                unit.events.push({
                  start: start.toDate(),
                  end: cur.toDate(),
                  class: 'disabled',
                  background: true,
                  split: unit.id,
                  disabled: true,
                })
              }
            }
            last = 'booking'
          }
        }
        if (!exist) {
          if (last === 'ban') {
            if (start) {
              unit.events.push({
                start: start.toDate(),
                end: cur.toDate(),
                class: 'disabled',
                background: true,
                split: unit.id,
                disabled: true,
              })
              start = null
            }
          }
          if (!start) start = cur
          last = 'noShift'
        }
        cur = cur.add(this.timeUnit, 'm')
      }

      // 結尾時段
      if (!this.isSamePeriod(endPeriod, lastPeriod)) {
        unit.events.push({
          start: dayjs(lastPeriod).add(this.timeUnit, 'm').toDate(),
          end: endPeriod,
          class: 'no-shift',
          split: unit.id,
          disabled: true,
          noShift: true,
          background: true,
        })
      }
    },

    dateChange: debounce(async function (e) {
      this.search.date = e.startDate
      this.loading = true
      this.curStart = new Date(e.startDate)
      this.select.start = new Date(e.startDate)
      for (const unit of this.unitList) {
        this.$set(unit, 'events', [])
        this.$set(unit, 'times', [])
        this.$set(unit, 'reservationCount', 0)
        this.getUnitDayPeriods(unit, new Date(e.startDate))
      }
      await this.getReservation(new Date(e.startDate))
      this.loading = false
    }, 300, { leading: false, trailing: true }),

    isSamePeriod (periodA, periodB) {
      const a = dayjs(periodA).format('HH:mm')
      const b = dayjs(periodB).format('HH:mm')
      return a === b
    },
    periodToDate (period) {
      const now = dayjs(this.curStart).format('YYYY/MM/DD')
      return dayjs(`${now} ${period}`).toDate()
    },
    async getReservation (date) {
      const payload = {
        shopId: this.shop,
        dateStart: dayjs(date).startOf('date').toDate(),
        dateEnd: dayjs(date).endOf('date').toDate(),
      }
      const count = await GetReservationCount(payload)

      const getReservation = async (payload) => {
        try {
          return await GetReservation(payload)
        } catch (err) {
          this.$message.error(err)
          return []
        }
      }
      const res = await getAllDataFromApi(
        count,
        getReservation,
        { ...payload, limit: 100, start: 0 },
      )
      const reservations = res.filter(i => get(i, 'AppointmentOrder.status') !== 'cancel')
      reservations.forEach(i => this.createReservationEvents(i))
    },
    onCancel (data) {
      const orders = get(data, 'AppointmentOrder.AppointmentReservations')
      const orderCode = get(data, 'AppointmentOrder.code')
      if (!orders) {
        this.dialog.delete = true
        this.deleteContent = '確定要取消此筆預約？'
        return
      }
      if (!orders.length || orders.length === 1) {
        this.dialog.delete = true
        this.deleteContent = '確定要取消此筆預約？'
        return
      }

      const ordersCount = orders.length

      this.deleteContent = `訂單編號 ${orderCode} 有 ${ordersCount} 筆預約紀錄，將同時取消 ${ordersCount} 筆預約紀錄。`
      this.dialog.delete = true
    },
    //= > 刪除(取消)預約
    async cancelReservation () {
      this.dialog.delete = false
      const [, err] = await UpdateReservationOrderStatus({
        shopId: this.shop,
        id: get(this.select.event, 'AppointmentOrder.id'),
        status: 'cancel',
      })
      if (err) return this.$message.error(err)
      this.dialog.info = false
      await this.dateChange({ startDate: this.curStart })
    },

    createReservationEvents (reservation) {
      if (!reservation) return
      const {
        AppointmentOrder,
        AppointmentUnit,
        numberCode,
        Member,
        start,
        end,
        userName,
        userPhone,
        fromNotSpecify,
      } = reservation
      const newUserName = get(AppointmentOrder, 'origin') === 'pubApt' ? `(訪客)${userName}` : userName
      const unitId = get(AppointmentUnit, 'id')
      if (!unitId) return
      const data = {
        title: '',
        data: { newUserName, userPhone, fromNotSpecify, Member, numberCode },
        start: new Date(start),
        end: new Date(end),
        split: unitId || 'noUnit',
        class: AppointmentOrder.status,
        reservation,
      }
      const target = find(this.unitList, { id: unitId })
      if (target) {
        target.reservationCount = target.reservationCount + 1
        target.events.push(data)
      }
    },

    showAttachServices (reservation) {
      const data = get(reservation, 'AppointmentServiceAttaches')
      const services = map(data, i => i.name)
      return services
    },
  },
})
</script>

<style lang="scss" scoped>
.daily-view {
  padding-bottom: 0 !important;
}
::v-deep .vuecal {
  @apply rounded-[10px];
}

::v-deep .day-view {
  @apply bg-white;
}

::v-deep .vuecal__bg {
  @apply bg-primary-30 ;
}

::v-deep .vuecal__body {
  @apply rounded-tl-[0px] rounded-tr-[0px] rounded-bl-[10px] rounded-br-[10px];
}

::v-deep .vuecal__header {
  @apply bg-primary-30 rounded-tl-[10px] rounded-tr-[10px];
}

::v-deep .vuecal__title-bar {
  @apply flex justify-center bg-primary-100 text-white h-[60px];
  @apply rounded-tl-[10px] rounded-tr-[10px];
}

::v-deep .day-split-header {
  // @apply border-gray-50 border-r border-t-0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::v-deep .vuecal__flex {
  .vuecal__title {
    @apply flex-none w-auto;
  }
  .vuecal__split-days-headers {
    @apply h-[62px] bg-primary-30 ml-[60px] mr-[15px] border-gray-40 border;
  }
}

::v-deep .vuecal__cell-split {
  @apply border-r border-r-gray-50;
}
::v-deep .vuecal__event {
  @apply border-[4px] border-l-[8px] rounded-[4px] cursor-pointer text-left text-[12px];
}
::v-deep .vuecal__event.no-shift{
  @apply bg-gray-40 border-none opacity-60;
}
::v-deep .vuecal__event.disabled{
  background: repeating-linear-gradient(-45deg, transparent, transparent 5px, var(--gray-50) 5px, var(--gray-50) 11px);/* IE 10+ */
  @apply text-gray-80 flex justify-center items-center ;
  @apply bg-gray-40 border-none opacity-60;
}

::v-deep .vuecal__event.wait{
  @apply bg-[#FBF2E7] border-[#E4A65F];
}
::v-deep .vuecal__event.cancel{
  @apply bg-danger-light border-danger;
}
::v-deep .vuecal__event.confirm{
  @apply bg-[#E1EDF9] border-[#3788D8];
}
::v-deep .vuecal__event.complete{
  @apply bg-[#F4F7E2] border-[#B5C93E];
}

::v-deep .vuecal__time-column {
  @apply w-[60px] bg-primary-30 ;
}

.create-reservation-wrapper {
  @apply border border-primary-100 p-[12px] rounded-[10px] flex-shrink-0;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  height: 700px;
  width: 600px;
}

::v-deep .cell-block {
  @apply hover:bg-primary-30;
}
.tag {
  @apply border border-info text-info bg-info-light p-[2px] rounded-[4px] text-center;
  @apply w-[fit-content];
}
</style>
